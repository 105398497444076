import GenericModal from "./GenericModal";

export type StandardModalProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  {
    onDismiss: () => void,
    isOpen: boolean,
    className?: string
  }

export default function StandardModal({ children, onDismiss, ...otherProps }: StandardModalProps) {
  return <GenericModal
    withBackdrop={true}
    backdropClassName={"flex flex-col justify-center items-center w-screen h-dvh"}
    className={"max-h-almostScreen w-4/5 sm:w-1/2 bg-white px-6 xs:px-10 pt-4 pb-8 shadow-sym-md overflow-auto"}
    onDismiss={onDismiss}
    {...otherProps}
  >
    <>
      <div className="flex flex-row justify-end mb-2">
        <button onClick={onDismiss}><b>╳</b></button>
      </div>
      {children}
    </>
  </GenericModal>;
}