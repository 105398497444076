import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBarMobileLogo from "assets/images/icons/dp-icon-green-orange.svg";
import SandwichIcon from "assets/images/icons/sandwich-menu-green.svg";
import NavBarLogo from "assets/images/logos/dp-logo-and-text-green.svg";
import { useOutsideClickEvents } from "hooks/useOutsideClickEvents";
import { setBurgerMenuOpen, setFullMobileSearchBoxOpen } from "reduxStore/slices/nav-bar/actions";
import { selectFullMobileSearchBoxOpen, selectSearchBoxHidden } from "reduxStore/slices/nav-bar/selectors";

import VersionSensitiveLink from "components/atoms/VersionSensitiveLink";
import BasketButton from "components/molecules/BasketButton";

import BasketModal from "./BasketModal";
import BurgerMenu from "./BurgerMenu";
import SearchBox from "./SearchBox";
import SearchSuggestions from "./SearchSuggestions";

const NavBar = () => {
  const dispatch = useDispatch();
  const fullMobileSearchBoxOpen = useSelector(selectFullMobileSearchBoxOpen);
  const searchBoxHidden = useSelector(selectSearchBoxHidden);
  const mdlRf = useRef(null);

  useOutsideClickEvents(
    [ mdlRf ],
    () => {
      dispatch(setFullMobileSearchBoxOpen(false));
    },
    [],
    [
      "pac-item-query",
      "pac-matched",
      "pac-item",
      "pac-icon",
      "pac-icon-marker",
      "pac-container",
      "pac-target-input",
      "pac-matched",
    ]
  );

  return (
    <div className="bg-white shadow-md text-center w-full z-50" ref={mdlRf}>
      <div className="p-6 px-6 xs:px-10 max-w-screen-xl m-auto">
        <div className="flex flex-row justify-between items-center">
          <div className="shrink-0">
            <VersionSensitiveLink to="/">
              <img src={NavBarLogo} alt="logo" className="h-8 hidden lg:block"/>
              {!fullMobileSearchBoxOpen && <img src={NavBarMobileLogo} alt="logo" className="h-8 block lg:hidden"/>}
            </VersionSensitiveLink>
          </div>
          <div className={`flex justify-center transition-all duration-300 overflow-hidden w-full ${searchBoxHidden ? "opacity-0 h-0": "xs:h-18 lg:h-22"}`}>
            <SearchBox allowMobileStyling={true} />
          </div>
          <div className="flex shrink-0 flex-row items-center">
            <div className="link-wrapper mx-8 shrink-0 hidden lg:block">
              <BasketButton />
            </div>
            <div className="link-wrapper hidden lg:block">
              <VersionSensitiveLink to="/categories">
                What can I donate?
              </VersionSensitiveLink>
            </div>
            {
              !fullMobileSearchBoxOpen && <button
                onClick={() => dispatch(setBurgerMenuOpen(true))}
                className="shrink-0"
                data-test-id="burger-menu-icon"
              >
                <img src={SandwichIcon} alt="menu" className="w-6 mx-2 lg:mx-8"/>
              </button>
            }
          </div>
        </div>
      </div>
      { !searchBoxHidden && <SearchSuggestions/> }
      <BurgerMenu/>
      <BasketModal />
    </div>
  );
};

export default NavBar;