import React, { Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import useFont from "hooks/useFont";
import { notificationsMiddleware } from "reduxStore/middlewares/notifications";
import { dpApi } from "reduxStore/services/api/api";
import { dpApiV2 } from "reduxStore/services/api-v2/apiV2";
import { carrierApi } from "reduxStore/services/carrier-points-api/api";

import CharityAdminRoute from "components/layouts/CharityAdminRoute";
import CheckoutPage from "components/layouts/CheckoutPage";
import ContentPage from "components/layouts/ContentPage";
import DefaultHomePage from "components/layouts/DefaultHomePage";
import EndUserRoute from "components/layouts/EndUserRoute";
import FullScreenPage from "components/layouts/FullScreenPage";
import HomeFullScreenPage from "components/layouts/HomeFullScreenPage";
import OrgAdminRoute from "components/layouts/OrgAdminRoute";
import OutboundCheckoutPage from "components/layouts/OutboundCheckoutPage";
import SuperAdminRoute from "components/layouts/SuperAdminRoute";
import UnauthorisedRoute from "components/layouts/UnauthorisedRoute";
import FullPageSpinner from "components/molecules/FullPageSpinner";
import ItemAlertModal from "components/organisms/ItemAlertModal";
import AdminHome from "components/views/AdminHome";

import reducer from "./reduxStore/reducers";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("G-9MZ4RLDVPH");
  ReactGA.send("pageview");
}

const DefaultSuspense = ({ children } : { children: JSX.Element }) => {
  return <Suspense fallback={<FullPageSpinner/>}>{children}</Suspense>;
};


const AccountActivation = React.lazy(() => import("components/views/AccountActivation"));
const AdminEditCharity = React.lazy(() => import("components/views/AdminEditCharity"));
const AddLabels = React.lazy(() => import("components/views/AddLabels"));
const BuyLabelsEmail = React.lazy(() => import("components/views/BuyLabelsEmail"));
const BuyLabelsCollectionAddress = React.lazy(() => import("components/views/BuyLabelsCollectionAddress"));
const BuyLabelsCollectionDate = React.lazy(() => import("components/views/BuyLabelsCollectionDate"));
const BuyLabelsPackingGuide = React.lazy(() => import("components/views/BuyLabelsPackingGuide"));
const BuyLabelsPayment = React.lazy(() => import("components/views/BuyLabelsPayment"));
const BuyLabelsPost = React.lazy(() => import("components/views/BuyLabelsPost"));
const BuyLabelsReview = React.lazy(() => import("components/views/BuyLabelsReview"));
const Categories = React.lazy(() => import("components/views/Categories"));
const CategoryLanding = React.lazy(() => import("components/views/CategoryLanding"));
const Charity = React.lazy(() => import("components/views/Charity"));
const CharityAddress = React.lazy(() => import("components/views/CharityAddress"));
const CharityDetails = React.lazy(() => import("components/views/CharityDetails"));
const CharityHome = React.lazy(() => import("components/views/CharityHome"));
const CharityOrders = React.lazy(() => import("components/views/CharityOrders"));
const CharityReceivedDonations = React.lazy(() => import("components/views/ReceivedDonations"));
const CharitySettings = React.lazy(() => import("components/views/CharitySettings"));
const Contact = React.lazy(() => import("components/views/Contact"));
const DropPointFinder = React.lazy(() => import("components/views/DropPointFinder"));
const EditCharity = React.lazy(() => import("components/views/EditCharity"));
const Emails = React.lazy(() => import("components/views/Emails"));
const EndUserHome = React.lazy(() => import("components/views/EndUserHome"));
const FAQ = React.lazy(() => import("components/views/FAQ"));
const GDPR = React.lazy(() => import("components/views/GDPR"));
const Home = React.lazy(() => import("components/views/Home"));
const HowToDonate = React.lazy(() => import("components/views/HowToDonate"));
const HowWeHelp = React.lazy(() => import("components/views/HowWeHelp"));
const HowWeHelpBusinesses = React.lazy(() => import("components/views/HowWeHelpBusinesses"));
const Logout = React.lazy(() => import("components/views/Logout"));
const MyPage = React.lazy(() => import("components/views/MyPage"));
const News = React.lazy(() => import("components/views/News"));
const Orders = React.lazy(() => import("components/views/Orders"));
const OurStory = React.lazy(() => import("components/views/OurStory"));
const OutboundAddLabels = React.lazy(() => import("components/views/OutboundAddLabels"));
const PackingGuide = React.lazy(() => import("components/views/PackingGuide"));
const Postage = React.lazy(() => import("components/views/Postage"));
const PostCheckout = React.lazy(() => import("components/views/PostCheckout"));
const ResetPassword = React.lazy(() => import("components/views/ResetPassword"));
const SearchResults = React.lazy(() => import("components/views/SearchResults"));
const SignUp = React.lazy(() => import("components/views/SignUp"));
const SignUpDonor = React.lazy(() => import("components/views/SignUpDonor"));
const SignUpStart = React.lazy(() => import("components/views/SignUpStart"));
const Terms = React.lazy(() => import("components/views/Terms"));
const TermsCampus = React.lazy(() => import("components/views/TermsCampus"));
const UpdatePassword = React.lazy(() => import("components/views/UpdatePassword"));


const store = configureStore({
  reducer: reducer as any,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(dpApi.middleware)
      .concat(dpApiV2.middleware)
      .concat(carrierApi.middleware)
      .concat(notificationsMiddleware)
});

setupListeners(store.dispatch);

function App() {
  const fontLoaded = useFont("Lato");

  if (!fontLoaded) {
    return <FullPageSpinner/>;
  }

  return <div className="font-lato flex flex-col h-dvh justify-between">
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<ContentPage/>}>
              <Route path="/activate/:uid/:token/" element={<DefaultSuspense><AccountActivation/></DefaultSuspense>}/>
              <Route path="/logout" element={<DefaultSuspense><Logout/></DefaultSuspense>}/>
              <Route path="/map" element={<DefaultSuspense><SearchResults/></DefaultSuspense>}/>
              <Route path="/ourstory" element={<DefaultSuspense><OurStory/></DefaultSuspense>}/>
              <Route path="/packingguide" element={<DefaultSuspense><PackingGuide/></DefaultSuspense>}/>
              <Route path="/faq" element={<DefaultSuspense><FAQ/></DefaultSuspense>}/>
              <Route path="/gdpr" element={<DefaultSuspense><GDPR/></DefaultSuspense>}/>
              <Route path="/terms" element={<DefaultSuspense><Terms/></DefaultSuspense>}/>
              <Route path="/terms-campus" element={<DefaultSuspense><TermsCampus/></DefaultSuspense>}/>
              <Route path="/how-to-use-droppoint" element={<DefaultSuspense><HowToDonate/></DefaultSuspense>}/>
              <Route path="/categories" element={<DefaultSuspense><Categories/></DefaultSuspense>}/>
              <Route path="/account/update-password" element={<DefaultSuspense><UpdatePassword/></DefaultSuspense>}/>
              <Route path="/add-labels/:charitySlug" element={<DefaultSuspense><AddLabels/></DefaultSuspense>}/>
              <Route path="/add-labels/:charitySlug/:type" element={<DefaultSuspense><AddLabels/></DefaultSuspense>}/>
              <Route path="/checkout" element={<CheckoutPage />}>
                <Route path="collection-address" element={<DefaultSuspense><BuyLabelsCollectionAddress/></DefaultSuspense>}/>
                <Route path="collection-date" element={<DefaultSuspense><BuyLabelsCollectionDate/></DefaultSuspense>}/>
                <Route path="packing-guide" element={<DefaultSuspense><BuyLabelsPackingGuide/></DefaultSuspense>}/>
                <Route path="payment" element={<DefaultSuspense><BuyLabelsPayment/></DefaultSuspense>}/>
                <Route path="post" element={<DefaultSuspense><BuyLabelsPost/></DefaultSuspense>}/>
                <Route path="review" element={<DefaultSuspense><BuyLabelsReview/></DefaultSuspense>}/>
                <Route path="email" element={<DefaultSuspense><BuyLabelsEmail/></DefaultSuspense>}/>
              </Route>
              <Route path="/post-checkout" element={<DefaultSuspense><PostCheckout/></DefaultSuspense>}/>
              <Route element={<CharityAdminRoute/>}>
                <Route path="/adminpanel" element={<DefaultSuspense><EditCharity/></DefaultSuspense>}/>
              </Route>
              <Route element={<OrgAdminRoute/>}>
                <Route path="/charity-admin">
                  <Route path="home" element={<DefaultSuspense><CharityHome/></DefaultSuspense>}/>
                  <Route path="settings" element={<DefaultSuspense><CharitySettings/></DefaultSuspense>}/>
                  <Route path="address" element={<DefaultSuspense><CharityAddress/></DefaultSuspense>}/>
                  <Route path="orders/:resetBasket" element={<DefaultSuspense><CharityOrders/></DefaultSuspense>}/>
                  <Route path="orders" element={<DefaultSuspense><CharityOrders/></DefaultSuspense>}/>
                  <Route path="received-donations" element={<DefaultSuspense><CharityReceivedDonations/></DefaultSuspense>}/>
                  <Route path="add-labels/:type" element={<DefaultSuspense><OutboundAddLabels/></DefaultSuspense>}/>
                  <Route path="add-labels" element={<DefaultSuspense><OutboundAddLabels/></DefaultSuspense>}/>
                  <Route path="checkout" element={<OutboundCheckoutPage />}>
                    <Route path="collection-date" element={<DefaultSuspense><BuyLabelsCollectionDate outbound={true} /></DefaultSuspense>}/>
                    <Route path="packing-guide" element={<DefaultSuspense><BuyLabelsPackingGuide outbound={true} /></DefaultSuspense>}/>
                    <Route path="payment" element={<DefaultSuspense><BuyLabelsPayment outbound={true}/></DefaultSuspense>}/>
                    <Route path="post" element={<DefaultSuspense><BuyLabelsPost outbound={true} /></DefaultSuspense>}/>
                    <Route path="review" element={<DefaultSuspense><BuyLabelsReview outbound={true} /></DefaultSuspense>}/>
                  </Route>
                </Route>
              </Route>
              <Route element={<EndUserRoute/>}>
                <Route path="/account">
                  <Route path="home" element={<DefaultSuspense><EndUserHome/></DefaultSuspense>}/>
                </Route>
              </Route>
              <Route element={<SuperAdminRoute/>}>
                <Route path="/admin/emails" element={<DefaultSuspense><Emails/></DefaultSuspense>}/>
                <Route path="/admin/orders" element={<DefaultSuspense><Orders/></DefaultSuspense>}/>
                <Route path="/admin/home" element={<DefaultSuspense><AdminHome/></DefaultSuspense>}/>
              </Route>
              <Route element={<UnauthorisedRoute/>}>
                <Route path="/signup/" element={<DefaultSuspense><SignUpStart/></DefaultSuspense>}/>
                <Route path="/signup/donor" element={<DefaultSuspense><SignUpDonor/></DefaultSuspense>}/>
                <Route path="/signup/:orgType" element={<DefaultSuspense><SignUp/></DefaultSuspense>}/>
                <Route path="/account/reset-password" element={<DefaultSuspense><ResetPassword/></DefaultSuspense>}/>
              </Route>
            </Route>
            <Route element={<FullScreenPage/>}>
              <Route path="/in-the-news" element={<DefaultSuspense><News/></DefaultSuspense>}/>
              <Route path="/how-we-help" element={<DefaultSuspense><HowWeHelp/></DefaultSuspense>}/>
              <Route path="/csr" element={<DefaultSuspense><HowWeHelpBusinesses/></DefaultSuspense>}/>
              <Route path="/postage" element={<DefaultSuspense><Postage/></DefaultSuspense>}/>
              <Route path="/contact" element={<DefaultSuspense><Contact/></DefaultSuspense>}/>
              <Route path="/droppoint-finder" element={<DefaultSuspense><DropPointFinder/></DefaultSuspense>}/>
              <Route path="/categories/:categorySlug" element={<DefaultSuspense><CategoryLanding/></DefaultSuspense>}/>
              <Route path="/ch/:charitySlug" element={<DefaultSuspense><Charity/></DefaultSuspense>}/>
              <Route element={<SuperAdminRoute fullPage={true}/>}>
                <Route path="/admin/charity-details" element={<DefaultSuspense><CharityDetails/></DefaultSuspense>}/>
                <Route path="/admin/charity" element={<DefaultSuspense><AdminEditCharity/></DefaultSuspense>}/>
                <Route path="/admin/charity/:charityId" element={<DefaultSuspense><AdminEditCharity/></DefaultSuspense>}/>
              </Route>
              <Route element={<OrgAdminRoute/>}>
                <Route path="/charity-admin/page" element={<DefaultSuspense><MyPage/></DefaultSuspense>}/>
              </Route>
            </Route>
            <Route element={<HomeFullScreenPage/>}>
              <Route path="/" element={<DefaultSuspense><Home/></DefaultSuspense>}/>
            </Route>
            <Route element={<FullScreenPage/>}>
              <Route path="/:charitySlug" element={<Charity/>}/>
            </Route>
            <Route path="*" element={<DefaultHomePage/>}/>
          </Routes>
          <ItemAlertModal/>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            className="xs:!bottom-4"
            bodyClassName="font-lato"
            pauseOnFocusLoss
            pauseOnHover
          />
        </BrowserRouter>
      </CookiesProvider>
    </Provider>
  </div>;
}

export default App;
