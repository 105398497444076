import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchBoxHidden } from "reduxStore/slices/nav-bar/actions";
import { selectSearchSuggestionsOpen } from "reduxStore/slices/nav-bar/selectors";
import { DivProps } from "types/dom";

import BasketButtonFloating from "components/molecules/BasketButtonFloating";
import CustomCookieConsent from "components/molecules/CustomCookieConsent";
import Footer from "components/organisms/Footer";
import NavBar from "components/organisms/NavBar";
import SaleBanner from "components/organisms/SaleBanner";

const BaseLayout = ({ children }: DivProps) => {
  const searchOpen = useSelector(selectSearchSuggestionsOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchBoxHidden(false));
  }, []);


  return <div className="flex flex-col w-screen h-dvh overflow-hidden">
    <NavBar />
    <CustomCookieConsent/>
    <div className="flex flex-col w-full h-full grow-1 relative">
      {searchOpen && <div className="absolute left-0 top-0 w-full h-full bg-white bg-opacity-70 z-30"></div>}
      <div id="contentScrollView" className="flex flex-col justify-between grow-1 w-full h-0 overflow-auto items-center relative">
        <div className="flex flex-col items-center w-full grow-1">
          {children}
        </div>
        <SaleBanner />
        <BasketButtonFloating/>
        <Footer/>
      </div>
    </div>
  </div>;
};

export default BaseLayout;