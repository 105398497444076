import { ActionType, createReducer } from "typesafe-actions";

import * as actions from "./actions";
import { ItemAlertsState } from "./types";

export type ItemAlertsAction = ActionType<typeof actions>;

const INITIAL_ITEM_ALERT_MODAL_STATE: ItemAlertsState = {
  ui: {
    itemAlertModalOpen: false,
  }
};

const itemAlertsReducer = createReducer(
  INITIAL_ITEM_ALERT_MODAL_STATE
).handleAction(
  actions.setItemAlertModalOpen,
  (state: ItemAlertsState, action) => {
    return {
      ...state,
      ui: {
        itemAlertModalOpen: action.payload
      }
    };
  }
);

export { itemAlertsReducer };
