import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { OpeningTimes } from "../api-v2/droppoints";
import { CarrierName } from "../api-v2/orders";

export type CarrierPointOpeningTimes = ([[string, string]] | null)[]
export type CarrierPoint = {
  address: string;
  name: string;
  carrier: CarrierName;
  city: string;
  locationId?: string;
  lat: number;
  lng: number;
  miles: string;
  openingTimes: CarrierPointOpeningTimes;
  disabledAccess: boolean;
}

// Define a service using a base URL and expected endpoints
export const carrierApi = createApi({
  reducerPath: "carrierApi",
  tagTypes: [ "CarrierPoint" ],
  endpoints: builder => ({
    getCarrierPoints: builder.query<CarrierPoint[], { lat: number; lng: number; }>({
      query: ({ lat, lng }) => ({
        url: "",
        params: {
          carriers: [ "hermes" ],
          locationTypes: [ "SHOP" ],
          lat,
          lng,
        },
        responseHandler: async response => {
          if (response.ok) {
            const responseJson = await response.json();
            return responseJson.results;
          }
          // Just return the error data
          return await response.json();
        }
      }),
      providesTags: [ "CarrierPoint" ]
    }),
  }),
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_CARRIER_API_ENDPOINT,
  }),
});

export const {
  useGetCarrierPointsQuery
} = carrierApi;