import { Outlet } from "react-router-dom";
import useCurrentUser from "hooks/useCurrentUser";

import FullPageSpinner from "components/molecules/FullPageSpinner";
import Login from "components/views/Login";

import DefaultHomePage from "./DefaultHomePage";

const EndUserRoute = ({ fullPage }: { fullPage?: boolean }) => {
  const { user, isLoading } = useCurrentUser();

  if (isLoading) {
    return <FullPageSpinner/>;
  }

  if (!user) {
    return <Login className={fullPage ? "mt-8" : ""} />;
  }

  if (user?.isEndUser) {
    return <Outlet />;
  }

  return <DefaultHomePage />;
};

export default EndUserRoute;
