import dayjs from "dayjs";

const oneDay = 24 * 60 * 60 * 1000;

export const ukBankHolidays = [
  "8-5-2020",
  "25-5-2020",
  "31-8-2020",
  "25-12-2020",
  "28-12-2020",
  "1-1-2021",
  "2-4-2021",
  "5-4-2021",
  "3-5-2021",
  "31-5-2021",
  "30-8-2021",
  "27-12-2021",
  "28-12-2021",
  "3-1-2022",
  "15-4-2022",
  "18-4-2022",
  "2-5-2022",
  "2-6-2022",
  "3-6-2022",
  "29-8-2022",
  "19-9-2022",
  "26-12-2022",
  "27-12-2022",
  "2-1-2023",
  "7-4-2023",
  "10-4-2023",
  "1-5-2023",
  "29-5-2023",
  "28-8-2023",
  "25-12-2023",
  "26-12-2023",

  "1-1-2024",
  "29-3-2024",
  "1-4-2024",
  "6-5-2024",
  "27-5-2024",
  "26-8-2024",
  "25-12-2024",
  "26-12-2024",

  "1-1-2025",
  "18-4-2025",
  "21-4-2025",
  "5-5-2025",
  "26-5-2025",
  "25-8-2025",
  "25-12-2025",
  "26-12-2025",

  "1-1-2026",
  "3-4-2026",
  "6-4-2026",
  "4-5-2026",
  "25-5-2026",
  "31-8-2026",
  "25-12-2026",
  "28-12-2026",

  "1-1-2027",
  "26-3-2027",
  "29-3-2027",
  "3-5-2027",
  "31-5-2027",
  "30-8-2027",
  "27-12-2027",
  "28-12-2027",

  "3-1-2028",
  "14-4-2028",
  "17-4-2028",
  "1-5-2028",
  "29-5-2028",
  "28-8-2028",
  "25-12-2028",
  "26-12-2028",

  "1-1-2029",
  "30-3-2029",
  "2-4-2029",
  "7-5-2029",
  "28-5-2029",
  "27-8-2029",
  "25-12-2029",
  "26-12-2029"
];

export const formatDate = (date: string, format: string) => {
  const [ year, month, day ] = date.split("-");
  return dayjs()
    .year(parseInt(year))
    .month(parseInt(month) - 1)
    .date(parseInt(day))
    .format(format);
};

export const dateToDashedDate = (date: Date) => {
  const tomorrowYear = `${date.getFullYear()}`;
  let tomorrowMonth = `${date.getMonth() + 1}`;
  let tomorrowDay = `${date.getDate()}`;

  if (tomorrowDay.length === 1) {
    tomorrowDay = `0${tomorrowDay}`;
  }
  if (tomorrowMonth.length === 1) {
    tomorrowMonth = `0${tomorrowMonth}`;
  }

  const tomorrowDate = `${tomorrowYear}-${tomorrowMonth}-${tomorrowDay}`;

  return tomorrowDate;
};

export const nextNDays = (numberOfDays: number) => {
  const dates = [];
  for (let i = 1; true; i++) {
    const nextDay = new Date(new Date().getTime() + (oneDay * i));
    dates.push(dateToDashedDate(nextDay));
    if (dates.length >= numberOfDays) {
      break;
    }
  }
  return dates;
};

export const nextNWeekdays = (numberOfDays: number) => {
  const dates = [];
  for (let i = 1; true; i++) {
    const nextDay = new Date(new Date().getTime() + (oneDay * i));
    if (ukBankHolidays.includes(`${nextDay.getDate()}-${nextDay.getMonth() + 1}-${nextDay.getFullYear()}`)) {
      continue;
    }
    if ([ 0, 6 ].includes(nextDay.getDay())) {
      continue;
    }

    dates.push(dateToDashedDate(nextDay));

    if (dates.length >= numberOfDays) {
      break;
    }
  }
  return dates;
};

