import { toast } from "react-toastify";
import { isFulfilled, isRejectedWithValue } from "@reduxjs/toolkit";
import { Middleware } from "redux";

const GENERIC_ERROR = "Oops, an unexpected error occurred. Please try again later";

export const notificationsMiddleware: Middleware = store => next => action => {
  if (isRejectedWithValue(action)) {
    switch (action.meta.arg.endpointName) {
    case "createOrg":
      if (action.payload.data.owner.email) {
        toast.error("An error occurred while trying to sign up - are you sure you have provided a valid email address?");
        return next(action);
      } else if (action.payload.data.owner.password) {
        toast.error(action.payload.data.owner.password[0]);
        return next(action);
      }
      toast.error("An error occurred while trying to sign up. Please try again later");
      return next(action);
    case "updateCharity":
      toast.error("An error occurred trying to update the charity. Please try again later");
      return next(action);
    case "updateCharityCollectionService":
      if (action.payload.data.phone) {
        toast.error("Oops, that does not appear to be a valid phone number");
        return next(action);
      }
      toast.error("An error occurred trying to update the charity. Please try again later");
      return next(action);
    case "updateCharityPostalService":
      toast.error("An error occurred trying to update the charity. Please try again later");
      return next(action);
    case "updateCharityDropoffService":
      toast.error("An error occurred trying to update the charity. Please try again later");
      return next(action);
    case "formUpdateCharity":
      toast.error("An error occurred trying to update the charity. Please try again later");
      return next(action);
    case "updateDroppoint":
      toast.error("An error occurred trying to update the drop point. Please try again later");
      return next(action);
    case "createItemQualityTier":
      toast.error("An error occurred trying to add this item. Please try again later");
      return next(action);
    case "updateItemQualityTier":
      toast.error("An error occurred trying to update this quality. Please try again later");
      return next(action);
    case "deleteItemQualityTier":
      toast.error("An error occurred trying to delete this item. Please try again later");
      return next(action);
    case "postMessage":
      toast.error("An error occurred trying to get in touch. Please try again later");
      return next(action);
    case "createItem":
      toast.error(action.payload.data.error || "An error occurred trying to delete this item. Please try again later");
      return next(action);
    case "createJWT":
      if (action.payload.data.detail) {
        toast.error(action.payload.data.detail);
        return next(action);
      }
      toast.error(GENERIC_ERROR);
      return next(action);
    case "requestLabelRefund":
      toast.error(GENERIC_ERROR);
      return next(action);
    case "createOrder":
      toast.error("An error occurred while trying to create your order. Please try again later");
      return next(action);
    case "createPaymentIntent":
      toast.error("An error occurred while trying to create a payment. Please try again later");
      return next(action);
    case "createEndUser":
      if (action.payload.data?.email) {
        toast.error(action.payload.data?.email[0]);
      } else if (action.payload.data?.username) {
        toast.error(action.payload.data?.username[0]);
      } else {
        try {
          const firstError = (Object.entries(action.payload.data)[0][1] as any)[0];
          toast.error(firstError);
        } catch {
          toast.error("An unexpected error occurred while trying to create an account. Please try again later");
        }
      }
      return next(action);
    case "resendActivationEmail":
      toast.error(GENERIC_ERROR);
      return next(action);
    case "confirmResetPassword":
      if (action.payload.data?.new_password) {
        toast.error(action.payload.data?.new_password[0]);
      } else if (action.payload.data?.token) {
        toast.error("We couldn't reset your password. That password reset link may have expired.");
      } else {
        toast.error(GENERIC_ERROR);
      }
      return next(action);
    case "updateEmail":
      if (action.payload.data?.email) {
        toast.error(action.payload.data?.email[0]);
      } else if (action.payload.data?.current_password) {
        toast.error(action.payload.data?.current_password[0]);
      } else {
        toast.error(GENERIC_ERROR);
      }
      return next(action);
    case "updatePassword":
      if (action.payload.data?.new_password) {
        toast.error(action.payload.data?.new_password[0]);
      } else if (action.payload.data?.current_password) {
        toast.error(action.payload.data?.current_password[0]);
      } else {
        toast.error(GENERIC_ERROR);
      }
      return next(action);
    case "activateAccount":
      if (action.payload.data?.detail === "Stale token for given user.") {
        toast.error("That activation link has already been used");
      } else {
        toast.error("Account activation failed - please try again later");
      }
      return next(action);
    }
  } else if (isFulfilled(action)) {
    switch (action.meta.arg.endpointName) {
    case "postMessage":
      toast.success("Message sent successfully");
      return next(action);
    case "resetPassword":
      toast.success("Request successful - if an account was associated to that email address we have sent an email to the account. Please check your email inbox to reset your password");
      return next(action);
    case "createEndUser":
    case "resendActivationEmail":
      toast.success("An activation email has been sent to your email address");
      return next(action);
    case "updateEmail":
      toast.success("Email updated successfully - please check your emails to re-activate your account");
      return next(action);
    case "updatePassword":
      toast.success("Password updated successfully");
      return next(action);
    case "activateAccount":
      toast.success("Account activated - you can now log in");
      return next(action);
    }
  }

  next(action);
};
