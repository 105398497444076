import { Outlet } from "react-router-dom";

import HomeBaseLayout from "./HomeBaseLayout";

const HomeFullScreenPage = () => {
  return <HomeBaseLayout>
    <div className="w-full h-full grow-1">
      <Outlet />
    </div>
  </HomeBaseLayout>;
};

export default HomeFullScreenPage;