import { useGetLoggedInUserQuery } from "reduxStore/services/api-v2/auth";

const useCurrentUser = () => {
  const { data: user, isLoading, isError } = useGetLoggedInUserQuery();

  return {
    isLoading,
    user: (user && !isError) ? {
      isAdmin: user.is_superuser,
      isCharityAdmin: user.is_charity_admin,
      isRetailerAdmin: user.is_retailer_admin,
      isEndUser: user.is_end_user,
      id: user.id,
      email: user.email,
      name: user.name || `${user.first_name} ${user.last_name}`.trim(),
    } : null
  };
};

export default useCurrentUser;