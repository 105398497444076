import { Outlet, useLocation } from "react-router-dom";
import { useBasket } from "hooks/useBasket";

import VersionSensitiveNavigate from "components/atoms/VersionSensitiveNavigate";

export type CheckoutPage = |
  "email" |
  "collectionAddress" |
  "collectionDate" |
  "post" |
  "packingGuide" |
  "review" |
  "payment"

export const checkoutPages: CheckoutPage[] = [
  "email",
  "collectionAddress",
  "collectionDate",
  "post",
  "packingGuide",
  "review",
  "payment"
];

export const firstCheckoutPageUrl = "/checkout/email";

export const checkoutPageUrls: { [currentPage in CheckoutPage]: string } = {
  email: "email",
  collectionAddress: "collection-address",
  collectionDate: "collection-date",
  post: "post",
  packingGuide: "packing-guide",
  review: "review",
  payment: "payment"
};

export const useGetNextPage = () => {
  const location = useLocation();
  const { hasDhlLabels, hasHermesLabels, hasCollectplusLabels } = useBasket();

  const locationParts = location.pathname.split("/");
  const currentLocation = locationParts[locationParts.length - 1];

  const nextPageMap: { [currentPage in CheckoutPage]: CheckoutPage | null } = {
    email: hasDhlLabels ? "collectionAddress" : "post",
    collectionAddress: "collectionDate",
    collectionDate: (hasHermesLabels || hasCollectplusLabels) ? "post" : "packingGuide",
    post: "packingGuide",
    packingGuide: "review",
    review: "payment",
    payment: null
  };

  const getNextPageUrl = (currentPage: CheckoutPage): string => {
    const nextPage = nextPageMap[currentPage];
    return nextPage ? `/checkout/${checkoutPageUrls[nextPage]}` : "/";
  };

  const currentPage = checkoutPages.find(page => checkoutPageUrls[page] === currentLocation);

  if (!currentPage) {
    return firstCheckoutPageUrl;
  }

  return getNextPageUrl(currentPage);
};

export default function CheckoutPage () {
  const location = useLocation();
  const { basket, numLabels, hasDhlLabels } = useBasket();

  const locationParts = location.pathname.split("/");
  const currentLocation = locationParts[locationParts.length - 1];

  const checkoutPagePermissions: { [currentPage in CheckoutPage]: boolean } = {
    email: numLabels > 0,
    collectionAddress: !!basket.email,
    collectionDate: !hasDhlLabels || !!basket.collectionAddress.postcode,
    post: !hasDhlLabels || !!basket.collectionDate,
    packingGuide: true,
    review: basket.packingGuideAccepted,
    payment: true
  };

  const getHasPermissions = (currentPage: CheckoutPage) => {
    for(const [ page, filter ] of Object.entries(checkoutPagePermissions)) {
      if (filter && (currentPage === page)) {
        return true;
      }
      if (!filter) {
        return false;
      }
    }
    return false;
  };

  const currentPage = checkoutPages.find(page => checkoutPageUrls[page] === currentLocation);

  if (!currentPage) {
    return <VersionSensitiveNavigate to="/" replace={true}/>;
  }

  if (!getHasPermissions(currentPage)) {
    if (getHasPermissions("email")) {
      return <VersionSensitiveNavigate to={firstCheckoutPageUrl} replace={true}/>;
    }
    return <VersionSensitiveNavigate to={"/"} replace={true}/>;
  }

  return <>
    <Outlet />
  </>;
}