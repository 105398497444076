import { useDispatch } from "react-redux";
import BasketIcon from "assets/images/icons/basket.svg";
import { useBasket } from "hooks/useBasket";
import { setBasketOpen } from "reduxStore/slices/basket/actions";


export default function BasketButton () {
  const { numLabels, numOutboundLabels } = useBasket();
  const dispatch = useDispatch();

  return <button
    onClick={() => {
      dispatch(setBasketOpen(true));
    }}
    className="relative"
  >
    <img src={BasketIcon} alt="map" className="h-8"/>
    {
      (numLabels + numOutboundLabels)
        ? <div className="absolute -right-3 -top-1 rounded-full text-white font-bold bg-dpGreen h-6 w-6">{numLabels + numOutboundLabels}</div>
        : null
    }
  </button>;
}