import { useBasket } from "hooks/useBasket";
import { ServiceName } from "reduxStore/services/api-v2/orders";
import { isInboundServiceName, isOutboundServiceName } from "types/basket";
import { DivProps } from "types/dom";

import BtnGreen from "components/atoms/BtnGreen";


const MAX_LABELS = 40;

type LabelQuantitySelectorProps = { service: ServiceName; charityId: number; tier: string; recipientId?: string; } & DivProps

export default function LabelQuantitySelector ({ service, charityId, tier, recipientId, className, ...otherProps }: LabelQuantitySelectorProps) {
  const isOutbound = !!recipientId;
  const { basket, addLabel, removeLabel, addOutboundLabel, removeOutboundLabel } = useBasket();
  const currentValue = (!isOutbound && isInboundServiceName(service))
    ? basket.labels[service][charityId][tier]
    :
    (isOutbound && recipientId && isOutboundServiceName(service))
      ? basket.outboundLabels[service][recipientId].labels[tier]
      : 0;

  return <div className={`inline-flex flex-row ${className || ""}`} { ...otherProps }>
    <input
      value={currentValue}
      className="w-14 xs:max-w-16 rounded-lg border-2 text-center px-4 py-1 mr-2 bg-white"
      disabled={true}
    />
    <div className="flex flex-col justify-center">
      <BtnGreen
        className="!px-2 !py-0 m-1 text-center hover:!no-underline"
        onClick={() => {
          if (!isOutbound && isInboundServiceName(service)) {
            addLabel(service, charityId, tier);
          }

          if (isOutbound && recipientId && isOutboundServiceName(service)) {
            addOutboundLabel(service, recipientId, tier);
          }
        }}
        disabled={currentValue >= MAX_LABELS}
      >
        +
      </BtnGreen>
      <BtnGreen
        className="!px-2 !py-0 m-1 text-center hover:!no-underline"
        onClick={() => {
          if (!isOutbound && isInboundServiceName(service)) {
            removeLabel(service, charityId, tier);
          }
          if (isOutbound && recipientId && isOutboundServiceName(service)) {
            removeOutboundLabel(service, recipientId, tier);
          }
        }}
      >
        -
      </BtnGreen>
    </div>
  </div>;
};