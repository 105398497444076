import { useEffect, useState } from "react";
import OrgIcon from "assets/images/icons/org.svg";
import { ActiveCharity, Charity, CharityMatchingSearch } from "reduxStore/services/api-v2/charities";
import { ImgProps } from "types/dom";

type OrgImageProps = ImgProps & {
  charity: Charity | ActiveCharity | CharityMatchingSearch;
  loadingPlaceholder?: React.ReactNode;
  updating?: boolean;
  onLoad?: () => void;
  placeholderClassName?: string;
  imgClassName?: string;
  width?: number | null;
  height?: number | null;
  fieldName: "logo_w30" | "logo_w40" | "logo_w90" | "logo_w140";
  lazy?: boolean;
}

const OrgImage = ({
  charity,
  width,
  height,
  fieldName,
  className,
  placeholderClassName,
  imgClassName,
  loadingPlaceholder,
  updating,
  lazy,
  onLoad,
  ...props
}: OrgImageProps) => {
  const [ loading, setLoading ] = useState(false);

  const thumb = charity[fieldName];

  useEffect(() => {
    if (thumb) {
      setLoading(true);
    }
  }, [ thumb ]);
  const style: { [key: string]: any } = {};
  const placeholderStyle: { [key: string]: any } = {};

  if (width) {
    style.width = width;
    placeholderStyle.width = width;
  }
  if (height) {
    style.height = height;
    placeholderStyle.height = height;
  }


  return <div style={style} className={`${className} relative w-fit`} {...props}>
    {
      (updating || loading) && (loadingPlaceholder || <div className="flex items-center justify-center">
        <img
          src={OrgIcon}
          alt="charity-logo"
          className={`w-full h-full ${placeholderClassName}`}
          style={placeholderStyle}
        />
      </div>)
    }
    <img
      src={lazy ? "" : thumb || OrgIcon}
      data-src={lazy ? thumb || OrgIcon : ""}
      alt="charity-logo"
      className={
        `
          w-full h-full
          ${thumb ? (imgClassName || "") : (placeholderClassName || "")}
          ${(loading || updating) ? "hidden" : ""}
          ${lazy ? "swiper-lazy" : ""}
        `
      }
      style={thumb ? style : placeholderStyle}
      onLoad={() => {
        setLoading(false);
        if (onLoad) {
          onLoad();
        }
      }}
    />
  </div>;
};

export default OrgImage;