import { dpApiV2 } from "./apiV2";

const extendedApi = dpApiV2.injectEndpoints({
  endpoints: builder => ({
    getNumParcelsSent: builder.query<{ labels_sent: number }, void>({
      query: () => ({
        url: "data/labels-sent/",
      }),
    }),
    getActiveData: builder.query<{
      num_live_charities: number;
      num_live_items: number;
      num_live_cities: number;
    }, void>({
      query: () => ({
        url: "data/active-counts/",
      }),
    }),
    getIsPostcodeRemote: builder.query<{ success: boolean; is_remote: boolean; }, string>({
      query: postcode => ({
        url: `data/postcode-remote/?postcode=${postcode}`,
      }),
    }),
    getIsPostcodeValid: builder.query<{ success: boolean; is_valid: boolean; }, string>({
      query: postcode => ({
        url: `data/postcode-valid/?postcode=${postcode}`,
      }),
    }),
  }),
  overrideExisting: true
});

export const {
  useGetNumParcelsSentQuery,
  useGetActiveDataQuery,
  useGetIsPostcodeRemoteQuery,
  useGetIsPostcodeValidQuery,
  useLazyGetIsPostcodeValidQuery
} = extendedApi;

export default extendedApi;