import { useSelector } from "react-redux";
import { useBasket } from "hooks/useBasket";
import { selectSaleBannerHidden } from "reduxStore/slices/nav-bar/selectors";

import BasketButton from "./BasketButton";

export default function BasketButtonFloating () {
  const saleBannerHidden = useSelector(selectSaleBannerHidden);
  const { numLabels, numOutboundLabels } = useBasket();
  if (numLabels === 0 && numOutboundLabels === 0) {
    return null;
  }
  return <div className={`sticky right-10 ${saleBannerHidden ? "bottom-4" : "bottom-32"} lg:hidden z-40 h-0 overflow-show w-full`}>
    <div className="absolute -top-20 right-10 p-4 rounded-full shadow-sym-lg bg-white">
      <BasketButton/>
    </div>
  </div>;
}