import { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";
import googleFonts from "google-fonts";

const useFont = (fontName: string) => {
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    googleFonts.add({ [fontName]: true });
    const font = new FontFaceObserver(fontName, { weight: 400 });
    font.load().then(() => {
      setLoaded(true);
    }, () => {
      setLoaded(true);
    });
  }, [ fontName ]);

  return loaded;
};

export default useFont;