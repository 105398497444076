import { Selector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { EntireState } from "../../types";

import { ItemAlertsState } from "./types";

export const selectItemAlertsState = (
  state: EntireState
): ItemAlertsState => state.itemAlerts;

export const selectItemAlertModalOpen: Selector<EntireState, boolean> = createSelector(
  selectItemAlertsState,
  (itemAlerts: ItemAlertsState) => itemAlerts.ui.itemAlertModalOpen
);
