import useCurrentUser from "hooks/useCurrentUser";

import GreyBox from "components/atoms/GreyBox";
import VersionSensitiveLink from "components/atoms/VersionSensitiveLink";
import VersionSensitiveNavigate from "components/atoms/VersionSensitiveNavigate";

export default function AdminHome () {
  const { user, isLoading } = useCurrentUser();

  return <div className="flex flex-col xs:flex-row items-start">
    <GreyBox className="flex flex-col mr-4 mb-4">
      <h2 className="text-xl">Admin</h2>
      <div className="font-bold flex flex-col">
        <VersionSensitiveLink to="/admin/charity">Charities</VersionSensitiveLink>
        <VersionSensitiveLink to="/admin/orders">Orders</VersionSensitiveLink>
        <VersionSensitiveLink to="/admin/emails">Emails</VersionSensitiveLink>
      </div>
    </GreyBox>
    <GreyBox className="flex flex-col mr-4 mb-4">
      <h2 className="text-xl">Exports</h2>
      <div className="font-bold flex flex-col">
        <VersionSensitiveLink to="/admin/droppoints.csv">Download DropPoints CSV</VersionSensitiveLink>
        <VersionSensitiveLink to="/admin/charities.csv">Download Charities CSV</VersionSensitiveLink>
        <VersionSensitiveLink to="/admin/bookings.csv">Download Outstanding Bookings CSV</VersionSensitiveLink>
        <VersionSensitiveLink to="/admin/labels.csv">Download Labels CSV</VersionSensitiveLink>
        <VersionSensitiveLink to="/admin/orders.csv?days=30">Download Orders CSV (last 30 days)</VersionSensitiveLink>
      </div>
    </GreyBox>
    <GreyBox className="flex flex-col mr-4 mb-4">
      <h2 className="text-xl">Tracking</h2>
      <div className="font-bold flex flex-col">
        <VersionSensitiveLink to="/api/tracking/runcheck">Run Tracking Check</VersionSensitiveLink>
        <VersionSensitiveLink to="/api/tracking/sendemails">Send Tracking Emails</VersionSensitiveLink>
        <VersionSensitiveLink to="/api/tracking/handleoldlabels">Tracking - Handle Old Labels</VersionSensitiveLink>
      </div>
    </GreyBox>
  </div>;
}