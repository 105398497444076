import { ActionType, createReducer } from "typesafe-actions";

import * as actions from "./actions";
import { BasketState } from "./types";

export type BasketAction = ActionType<typeof actions>;

const INITIAL_BASKET_STATE: BasketState = {
  ui: {
    basketOpen: false,
  }
};

const basketReducer = createReducer(
  INITIAL_BASKET_STATE
).handleAction(
  actions.setBasketOpen,
  (state: BasketState, action) => {
    return {
      ...state,
      ui: {
        basketOpen: action.payload
      }
    };
  }
);

export { basketReducer };
