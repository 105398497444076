import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import cookie from "utils/cookie";

type NormalizedData<T> = { ids: number[], byId: { [id: number]: T}}
export function normalizeData<T>(data: { id: number }[]): NormalizedData<T> {
  return {
    ids: data.map(({ id }) => id),
    byId: data.reduce(
      (prev, next) => ({
        ...prev,
        [next.id]: next
      }),
      {}
    )
  };
};

// Define a service using a base URL and expected endpoints
export const dpApi = createApi({
  reducerPath: "dpApi",
  tagTypes: [ "Charity", "User", "Item", "Category", "Email", "Order" ],
  endpoints: ()=> ({}),
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
    credentials: "include",
    prepareHeaders: headers => {
      headers.set("DP_AUTH", cookie.get("id") || "");

      // Something weird here - when we have form data we need to set
      // a boundary on the content-type header. This happens automatically
      // if we delete the content type here
      if (headers.get("Content-Type")?.includes("form-data")) {
        headers.delete("Content-Type");
      } else {
        headers.set(
          "Content-Type",
          headers.get("Content-Type") || "application/json"
        );
      }
      return headers;
    }
  })
});
