import { Location } from "types/locations";
import { createAction } from "typesafe-actions";

export const setSearchedItem = createAction(
  "@nav-bar/setSearchedItem"
)<string>();

export const setTypedItem = createAction(
  "@nav-bar/setTypedItem"
)<{ value: string, hideSuggestions?: boolean}>();

export const setSearchSuggestionsOpen = createAction(
  "@nav-bar/setSearchSuggestionsOpen"
)<boolean>();

export const setBurgerMenuOpen = createAction(
  "@nav-bar/setBurgerMenuOpen"
)<boolean>();

export const setFullMobileSearchBoxOpen = createAction(
  "@nav-bar/setFullMobileSearchBoxOpen"
)<boolean>();

export const setSearchBoxHidden = createAction(
  "@nav-bar/setSearchBoxHidden"
)<boolean>();

export const setSaleBannerHidden = createAction(
  "@nav-bar/setSaleBannerHidden"
)<boolean>();
