import { Selector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { EntireState } from "../../types";

import { BasketState } from "./types";

export const selectBasketState = (
  state: EntireState
): BasketState => state.basket;

export const selectBasketOpen: Selector<EntireState, boolean> = createSelector(
  selectBasketState,
  (basket: BasketState) => basket.ui.basketOpen
);
