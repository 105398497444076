import { useDispatch } from "react-redux";
import { setItemAlertModalOpen } from "reduxStore/slices/item-alerts/actions";

import LinkButton from "components/atoms/LinkButton";

export default function NoItemResultsBlurb({ itemName }: { itemName?: string | null }) {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-row justify-center w-full p-10">
      <div className="text-2xl">
        <div className="mb-6">
          Oops, we didn&apos;t find any charities accepting <span className="text-dpGreen">{itemName || "your searched item"}</span>.
        </div>
        <div>
          <LinkButton className="font-bold" text="Click here" onClick={() => {
            dispatch(setItemAlertModalOpen(true));
          }}/> to let us know about the items you&apos;d like to donate and we&apos;ll get to work to find them a new home
        </div>
      </div>
    </div>
  );
}