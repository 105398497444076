import { ButtonProps } from "types/dom";

import BtnBase from "./BtnBase";

const BtnGreen = ({ className, ...props }: ButtonProps) => {
  return <BtnBase
    {...props}
    className={`
      ${className || ""} bg-gradient-to-t from-dpGreenDark to-dpGreen
      text-white bg-dpGreen border-dpGreenDark
    `}
  />;
};

export default BtnGreen;