import { Outlet } from "react-router-dom";
import useCurrentUser from "hooks/useCurrentUser";

import FullPageSpinner from "components/molecules/FullPageSpinner";

import DefaultHomePage from "./DefaultHomePage";

const UnauthorisedRoute = () => {
  const { user, isLoading } = useCurrentUser();

  if (isLoading) {
    return <FullPageSpinner/>;
  }

  if (user) {
    return <DefaultHomePage />;
  }

  return <Outlet />;
};

export default UnauthorisedRoute;
