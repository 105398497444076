import { ActionType, createReducer } from "typesafe-actions";

import * as actions from "./actions";
import { NavBarState } from "./types";

export type NavBarAction = ActionType<typeof actions>;

const INITIAL_NAV_STATE: NavBarState = {
  data: {
    searchedItem: "",
    typedItem: ""
  },
  ui: {
    searchSuggestionsOpen: false,
    burgerMenuOpen: false,
    fullMobileSearchBoxOpen: false,
    searchBoxHidden: true,
    saleBannerHidden: false,
  }
};

const navBarReducer = createReducer(
  INITIAL_NAV_STATE
).handleAction(
  actions.setSearchedItem,
  (state: NavBarState, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        searchedItem: action.payload
      }
    };
  }
).handleAction(
  actions.setTypedItem,
  (state: NavBarState, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        typedItem: action.payload.value
      },
      ui: {
        ...state.ui,
        searchSuggestionsOpen: !action.payload.hideSuggestions
      }
    };
  }
).handleAction(
  actions.setSearchSuggestionsOpen,
  (state: NavBarState, action) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        searchSuggestionsOpen: action.payload
      }
    };
  }
).handleAction(
  actions.setBurgerMenuOpen,
  (state: NavBarState, action) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        burgerMenuOpen: action.payload
      }
    };
  }
).handleAction(
  actions.setFullMobileSearchBoxOpen,
  (state: NavBarState, action) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        fullMobileSearchBoxOpen: action.payload
      }
    };
  }
).handleAction(
  actions.setSearchBoxHidden,
  (state: NavBarState, action) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        searchBoxHidden: action.payload
      }
    };
  }
).handleAction(
  actions.setSaleBannerHidden,
  (state: NavBarState, action) => {
    return {
      ...state,
      ui: {
        ...state.ui,
        saleBannerHidden: action.payload
      }
    };
  }
);

export { navBarReducer };
