import { Outlet } from "react-router-dom";

import BaseLayout from "./BaseLayout";

const FullScreenPage = () => {
  return <BaseLayout>
    <div className="w-full h-full grow-1 relative">
      <Outlet />
    </div>
  </BaseLayout>;
};

export default FullScreenPage;