import { AddressOptionalLatLng, AddressWithName } from "types/addresses";

const validBuildingNo = (buildingNo: string) => buildingNo && (buildingNo.length <= 10) && (buildingNo.length > 0);
const validPostcode = (postcode: string) => postcode && postcode.match(/[A-Za-z]{1,2}[0-9][0-9A-Za-z]?\s?[0-9][A-Za-z]{2}/) && (postcode.length < 9);
const validAddressLine = (line: string) => line && line.match(/^(\p{L}|\p{N}|\p{P}|\p{Z})+$/gu) && line.length < 33;
const validStreet = (street: string) => street && (street.length < 100) && (street.length > 0);
const validCity = (city: string) => city && (city.length < 100) && (city.length > 0);
const validAddress = (address: AddressOptionalLatLng) =>(
  address.building_no
  && address.postcode
  && address.street
  && address.city
  && validBuildingNo(address.building_no)
  && validPostcode(address.postcode)
  && validStreet(address.street)
  && validCity(address.city)
);

export default {
  email (it: string) { return it.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/); },
  phone (it: string) { return (it && it.match(/^\+?[0-9]{10}/)) && !(it.match(/[0-9]{15}/)); },
  mobilePhone (it: string) { return !!(it && it.match(/^((\+?44\s?|0)7(\d{3})\s?\d{3}\s?\d{3})$/)); },
  buildingNo (it: string) { return validBuildingNo(it); },
  street (it: string) { return validStreet(it); },
  addressLine (it: string) { return validAddressLine(it); },
  city (it: string) { return validCity(it); },
  postcode (it: string) { return validPostcode(it); },
  address (it: AddressOptionalLatLng) { return validAddress(it); },
  addressWithName (it: AddressWithName) {
    return (
      it.name
      && validAddress(it)
    );
  },
};
