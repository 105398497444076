import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import validation from "@utils/validation";
import GreyCross from "assets/images/icons/grey-circle-cross.svg";
import SearchMag from "assets/images/icons/search-mag-green.svg";
import useGoogleMaps from "hooks/useGoogleMaps";
import { useVersionSensitiveNavigate } from "hooks/useVersionSensitiveNavigate";
import { setFullMobileSearchBoxOpen, setTypedItem } from "reduxStore/slices/nav-bar/actions";
import { selectFullMobileSearchBoxOpen, selectTypedItem } from "reduxStore/slices/nav-bar/selectors";
import { DivProps } from "types/dom";

import LocationCookieAutoComplete from "components/molecules/LocationCookieAutoComplete";


const FullSearchBox = ({ className, includeLocation = true, ...props }: DivProps & { includeLocation?: boolean }) => {
  const [ cookies, setCookie ] = useCookies([ "location" ]);
  const itemSearchFilter = useSelector(selectTypedItem);
  const itemInputReference = useRef(null);

  const setItemSearchFilter = (itemString: string) => dispatch(setTypedItem({ value: itemString }));

  const dispatch = useDispatch();
  const navigate = useVersionSensitiveNavigate();

  useEffect(() => {
    if (itemInputReference && itemInputReference.current) {
      (itemInputReference!.current as any).focus();
    }
  }, []);


  const doSearch = () => {
    if (validation.postcode(itemSearchFilter)) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: itemSearchFilter }, (results, status) => {
        if (results && (status === google.maps.GeocoderStatus.OK)) {
          const { lat, lng } = results[0].geometry.location;
          const location = {
            lat: lat() || null,
            lng: lng() || null,
            name: results[0].formatted_address || itemSearchFilter
          };
          setCookie("location", JSON.stringify(location), {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            path: "/"
          });
          setItemSearchFilter("");
          navigate("/map?itemSearch");
        }
      });
    } else {
      navigate(`/map?itemSearch=${encodeURIComponent(itemSearchFilter || "")}`);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = e => {
    if (e.key === "Enter") {
      doSearch();
    }
  };

  const { isLoaded: isGoogleMapsLoaded } = useGoogleMaps();

  if (!isGoogleMapsLoaded) {
    return null;
  }


  return <div
    className={`${className} border-dpGreen border-2 rounded-lg flex flex-col grow-1 xs:mx-10 max-w-lg relative justify-center ${includeLocation ? "" : "py-2"}`}
    {...props}
  >
    <div className={`item-search-wrapper flex items-center px-3 py-1 lg:py-2 ${includeLocation ? "" : "h-12"}`}>
      <input
        placeholder="What's cluttering your home?"
        onChange={e => setItemSearchFilter(e.target.value)}
        value={itemSearchFilter}
        className="outline-0 w-0 grow-1 lg:w-80"
        ref={itemInputReference}
        onKeyDown={handleKeyDown}
        data-test-id="items-input"
      />
      {
        itemSearchFilter && <img
          src={GreyCross}
          alt="Clear your search"
          onClick={() => {
            setItemSearchFilter("");
          }}
          className="cursor-pointer absolute right-20 hidden xs:block"
        />
      }
    </div>
    {includeLocation && <hr className="w-full h-0.5 bg-dpLightGray border-0"/>}
    <div className="absolute top-0 bottom-0 right-0 w-14 overflow-hidden rounded-lg">
      <div
        className="absolute -top-2 -bottom-2 -right-2 w-full border-2 border-dpGreen bg-dpLightGreen px-1 flex justify-center rounded-l-half"
        data-test-id="nav-bar-search-mag"
      >
        <button
          onClick={doSearch}
        >
          <img src={SearchMag} alt="search icon"/>
        </button>
      </div>
    </div>
    {includeLocation && <div className="location-search-wrapper flex px-3 py-1 lg:py-2">
      <LocationCookieAutoComplete className="outline-0 w-full" />
    </div>}
  </div>;
};

const MobileSearchBox = (props: DivProps) => {
  const fullMobileSearchBoxOpen = useSelector(selectFullMobileSearchBoxOpen);
  const dispatch = useDispatch();
  const itemSearchFilter = useSelector(selectTypedItem);
  const className = props.className;

  return <>
    {fullMobileSearchBoxOpen && <FullSearchBox {...props}/>}
    <div {...props} className={`${className} ${fullMobileSearchBoxOpen ? "hidden" : ""}`}>
      {!fullMobileSearchBoxOpen && <button
        onClick={() => dispatch(setFullMobileSearchBoxOpen(true))}
        className={`${fullMobileSearchBoxOpen ? "hidden": ""}`}
      >
        <div className="border-dpGreen border-2 rounded-lg grow-1 py-2 px-10 mx-2 flex flex-row">
          <div className="mr-4 font-medium text-dpGreenDark">
            {itemSearchFilter || "SEARCH"}
          </div>
          <img src={SearchMag} alt="Search icon" className="w-6"/>
        </div>
      </button>}
    </div>
  </>;
};

const SearchBox = ({ allowMobileStyling, includeLocation = true }: { allowMobileStyling?: boolean, includeLocation?: boolean}) => {
  if (!allowMobileStyling) {
    return <FullSearchBox includeLocation={includeLocation}/>;
  }

  return <>
    <FullSearchBox className="hidden xs:block" includeLocation={includeLocation}/>
    <MobileSearchBox className="block xs:hidden h-16 flex flex-row "/>
  </>;
};

export default SearchBox;