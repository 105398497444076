import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBasket } from "hooks/useBasket";
import useCurrentCharity from "hooks/useCurrentCharity";
import { useVersionSensitiveNavigate } from "hooks/useVersionSensitiveNavigate";
import { setBasketOpen } from "reduxStore/slices/basket/actions";
import { selectBasketOpen } from "reduxStore/slices/basket/selectors";

import BtnGreen from "components/atoms/BtnGreen";
import LinkButton from "components/atoms/LinkButton";
import RadioSelector from "components/molecules/RadioSelector";
import StandardModal from "components/molecules/StandardModal";
import BasketContents from "components/organisms/BasketContents";

import BasketOutboundContents from "./BaskoutOutboundContents";


export default function BasketModal () {
  const { numLabels, numOutboundLabels, hasOutboundDhlLabels } = useBasket();
  const dispatch = useDispatch();
  const basketOpen = useSelector(selectBasketOpen);

  const navigate = useVersionSensitiveNavigate();

  const { charity } = useCurrentCharity();
  const [ showOutbound, setShowOutbound ] = useState(numOutboundLabels > 0);

  return <StandardModal
    onDismiss={() => dispatch(setBasketOpen(false))}
    isOpen={basketOpen}
  >
    <div>
      <h2 className="font-bold text-xl">Your Basket</h2>
      {
        charity && <div className="w-full flex flex-row justify-center mb-4">
          <RadioSelector
            options={[
              {
                key: "INBOUND",
                text: "Inbound Basket",
                mobileText: "Inbound"
              },
              {
                key: "OUTBOUND",
                text: "Outbound Basket",
                mobileText: "Outbound"
              },
            ]}
            value={showOutbound ? "OUTBOUND" : "INBOUND"}
            onSelect={key => {
              if (key === "OUTBOUND") {
                setShowOutbound(true);
              } else {
                setShowOutbound(false);
              }
            }}
          />
        </div>
      }
      {
        showOutbound
          ? (
            (numOutboundLabels > 0)
              ? <div>
                <div className="text-left">
                  <BasketOutboundContents/>
                </div>
                <BtnGreen
                  className="mt-4"
                  onClick={() => {
                    dispatch(setBasketOpen(false));
                    if (hasOutboundDhlLabels) {
                      return navigate("/charity-admin/checkout/collection-date");
                    }
                    return navigate("/charity-admin/checkout/post");
                  }}
                >
                Go to checkout
                </BtnGreen>
              </div>
              : <div className="text-lg">
                Your basket is currently empty!
              </div>
          )
          : (
            (numLabels > 0)
              ? <div>
                <div className="text-left">
                  <BasketContents/>
                </div>
                <BtnGreen
                  className="mt-4"
                  onClick={() => {
                    dispatch(setBasketOpen(false));
                    navigate("/checkout/email");
                  }}
                >
                  Go to checkout
                </BtnGreen>
              </div>
              : <div className="text-lg">
                Your basket is currently empty!
                <br/>
                Find causes to donate to by searching from the navbar.
                {
                  (numOutboundLabels > 0) && <div>
                    <br/>
                    Your <LinkButton onClick={() => setShowOutbound(true)} text="outbound basket"/> contains {numOutboundLabels} labels
                  </div>
                }
              </div>
          )
      }
    </div>
  </StandardModal>;
}