import CookieConsent from "react-cookie-consent";

export default function CustomCookieConsent() {
  return <CookieConsent
    location="top"
    buttonText="Ok"
    cookieName="compliance"
    disableStyles={true}
    buttonClasses="bg-dpGreenDark py-2 px-6 text-white rounded-lg shadow-inner border-2 border-black"
    containerClasses="z-10 top-0 left-0 right-0 lg:h-28 w-screen bg-dpLightGreen flex flex-col lg:flex-row text-xl px-10 py-4 border-y-1 border-dpLightGray shadow-inner items-center justify-center"
    contentClasses="pb-4 lg:pb-0 lg:pr-10"
    extraCookieOptions={{ domain: process.env.REACT_APP_COOKIE_DOMAIN }}
  >
    This website uses cookies to provide a better browsing experience.
  </CookieConsent>;
}