import { Outlet } from "react-router-dom";
import useCurrentCharity from "hooks/useCurrentCharity";
import useCurrentUser from "hooks/useCurrentUser";

import FullPageSpinner from "components/molecules/FullPageSpinner";
import CharityAdminBanners from "components/organisms/CharityAdminBanners";
import Login from "components/views/Login";

import DefaultHomePage from "./DefaultHomePage";

const OrgAdminRoute = () => {
  const { user, isLoading: isLoadingUser } = useCurrentUser();
  const { charity, isLoading: isLoadingCharity } = useCurrentCharity();

  const isLoading = isLoadingUser || isLoadingCharity;

  if (isLoading) {
    return <FullPageSpinner/>;
  }

  if (!user) {
    return <Login />;
  }

  if (charity) {
    return <>
      <CharityAdminBanners charity={charity}/>
      <Outlet />
    </>;
  }

  return <DefaultHomePage />;
};

export default OrgAdminRoute;
