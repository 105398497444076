import React from "react";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  text: string;
}


const LinkButton = ({ text, className, ...props }: Props) => {
  return <button
    className={`${className} text-dpGreen`}
    {...props}
  >
    {text}
  </button>;
};

export default LinkButton;