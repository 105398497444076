import { InboundServiceName, OutboundServiceName, ServiceName } from "reduxStore/services/api-v2/orders";

import { AddressOptionalLatLng, AddressWithName } from "./addresses";

export type RecipientName = {
  firstName: string;
  lastName: string;
}

export type ContactDetails = {
  email: string;
  phone: string;
}

export type Basket = {
  labels: {
    [ carrier in InboundServiceName ]: {
      [ charityId: number]: {
        [ tier: string ]: number;
      };
    };
  };
  outboundLabels: {
    [ carrier in OutboundServiceName ]: {
      [ recipientId: string]: {
        labels: {
          [ tier: string ]: number;
        }
      };
    };
  };
  outboundRecipientIds: string[];
  recipientAddresses: {
    [ recipientId: string ]: AddressOptionalLatLng;
  };
  recipientNames: {
    [ recipientId: string ]: RecipientName;
  };
  recipientContactDetails: {
    [ recipientId: string ]: ContactDetails;
  }
  collectionAddress: AddressOptionalLatLng;
  collectionDate: string;
  collectionName: string;
  collectionTelephone: string;
  packingGuideAccepted: boolean;
  post: boolean;
  postToAddress: AddressWithName;
  donate: boolean;
  donationAmount: number;
  email: string;
}

export const DEFAULT_ADDRESS: AddressOptionalLatLng = {
  building_no: "",
  street: "",
  line2: "",
  city: "",
  postcode: "",
};

export const DEFAULT_ADDRESS_WITH_NAME: AddressWithName = {
  name: "",
  building_no: "",
  street: "",
  line2: "",
  city: "",
  postcode: "",
};

export const DEFAULT_BASKET: Basket = {
  labels: {
    hermesStores: {},
    collectplus: {},
    dhlInbound: {},
  },
  outboundLabels: {
    hermesStores: {},
    collectplus: {},
    dhlOutbound: {},
  },
  outboundRecipientIds: [],
  recipientAddresses: {},
  recipientNames: {},
  recipientContactDetails: {},
  collectionAddress: DEFAULT_ADDRESS,
  collectionDate: "",
  collectionName: "",
  collectionTelephone: "",
  packingGuideAccepted: false,
  post: false,
  postToAddress: DEFAULT_ADDRESS_WITH_NAME,
  donate: false,
  donationAmount: 0,
  email: ""
};

export const INBOUND_SERVICE_NAMES: InboundServiceName[] = [ "dhlInbound", "hermesStores", "collectplus" ];
export const OUTBOUND_SERVICE_NAMES: OutboundServiceName[] = [ "dhlOutbound", "hermesStores", "collectplus" ];


export const isInboundServiceName = (serviceName: ServiceName): serviceName is InboundServiceName => {
  return (INBOUND_SERVICE_NAMES as ServiceName[]).includes(serviceName);
};

export const isOutboundServiceName = (serviceName: ServiceName): serviceName is OutboundServiceName => {
  return (OUTBOUND_SERVICE_NAMES as ServiceName[]).includes(serviceName);
};
