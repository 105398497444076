import FooterLogoSmall from "assets/images/logos/dp-logo-green.svg";
import FooterLogo from "assets/images/logos/dp-logo-text-green.svg";
import FaceBookLogo from "assets/images/social/coloured/facebook.svg";
import InstagramLogo from "assets/images/social/coloured/instagram.png";
import LinkedInLogo from "assets/images/social/coloured/linkedin.svg";
import TwitterLogo from "assets/images/social/coloured/twitter.svg";
import Grass from "assets/images/textures/grass-background.svg";

import VersionSensitiveLink from "components/atoms/VersionSensitiveLink";

const SocialIcon = ({ imageSource, href, alt }: { imageSource: string, href: string, alt: string }) => {
  return <a href={href}>
    <img src={imageSource} alt={alt} className="h-5 md:h-6 px-1 shrink-0"/>
  </a>;
};

const Footer = () => {
  return <div className="shadow-inner flex-col items-center w-full border-t-1 border-dpLightGray bg-white">
    <div className="px-4 md:px-10 pt-4 md:pt-6 pb-4 flex flex-row justify-between w-full max-w-screen-xl m-auto">
      <VersionSensitiveLink to="/">
        <img src={FooterLogo} alt="logo" className="h-6 hidden md:block"/>
        <img src={FooterLogoSmall} alt="logo" className="h-6 md:hidden"/>
      </VersionSensitiveLink>
      <div className="flex flex-row">
        <a href="https://icons8.com/" className="px-2 md:px-4 hover:underline">
          <div className="hidden md:block">
            icons by icons8
          </div>
          <div className="md:hidden">
            icons8
          </div>
        </a>
        <VersionSensitiveLink to="/terms" className="px-2 md:px-4 hover:underline">
          <div className="hidden md:block">
            Terms & Conditions
          </div>
          <div className="md:hidden">
            Ts & Cs
          </div>
        </VersionSensitiveLink>
        <div className="flex flex-row px-2 md:px-4 items-center shrink-0">
          <SocialIcon
            href="http://facebook.com/DropPointHQ"
            imageSource={FaceBookLogo}
            alt="facebook"
          />
          <SocialIcon
            href="https://www.instagram.com/droppointhq"
            imageSource={InstagramLogo}
            alt="instagram"
          />
          <SocialIcon
            href="http://twitter.com/droppointhq"
            imageSource={TwitterLogo}
            alt="twitter"
          />
          <SocialIcon
            href="https://www.linkedin.com/company/droppointlive"
            imageSource={LinkedInLogo}
            alt="linkedin"
          />
        </div>
      </div>
    </div>
    <div className="relative h-12 overflow-hidden hidden md:block">
      <img src={Grass} alt="grass" className="w-full absolute top-0"/>
    </div>
  </div>;
};

export default Footer;