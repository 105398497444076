import { Outlet, useLocation } from "react-router-dom";
import { useBasket } from "hooks/useBasket";

import VersionSensitiveNavigate from "components/atoms/VersionSensitiveNavigate";

export type OutboundCheckoutPage = |
  "collectionDate" |
  "post" |
  "packingGuide" |
  "review" |
  "payment"

export const checkoutPages: OutboundCheckoutPage[] = [
  "collectionDate",
  "post",
  "packingGuide",
  "review",
  "payment"
];

export const firstCheckoutPageUrlWithDhl = "/charity-admin/checkout/collection-date";
export const firstCheckoutPageUrlWithoutDhl = "/charity-admin/checkout/post";

export const checkoutPageUrls: { [currentPage in OutboundCheckoutPage]: string } = {
  collectionDate: "collection-date",
  post: "post",
  packingGuide: "packing-guide",
  review: "review",
  payment: "payment"
};

export const useGetNextOutboundPage = () => {
  const location = useLocation();
  const { hasOutboundDhlLabels, hasOutboundHermesLabels, hasOutboundCollectplusLabels } = useBasket();

  const locationParts = location.pathname.split("/");
  const currentLocation = locationParts[locationParts.length - 1];

  const nextPageMap: { [currentPage in OutboundCheckoutPage]: OutboundCheckoutPage | null } = {
    collectionDate: (hasOutboundHermesLabels || hasOutboundCollectplusLabels) ? "post" : "packingGuide",
    post: "packingGuide",
    packingGuide: "review",
    review: "payment",
    payment: null
  };

  const getNextPageUrl = (currentPage: OutboundCheckoutPage): string => {
    const nextPage = nextPageMap[currentPage];
    return nextPage ? `/charity-admin/checkout/${checkoutPageUrls[nextPage]}` : "/";
  };

  const currentPage = checkoutPages.find(page => checkoutPageUrls[page] === currentLocation);

  if (!currentPage) {
    return hasOutboundDhlLabels ? firstCheckoutPageUrlWithDhl : firstCheckoutPageUrlWithoutDhl;
  }

  return getNextPageUrl(currentPage);
};

export default function OutboundCheckoutPage () {
  const location = useLocation();
  const { basket, numOutboundLabels, hasOutboundDhlLabels } = useBasket();

  const locationParts = location.pathname.split("/");
  const currentLocation = locationParts[locationParts.length - 1];

  const checkoutPagePermissions: { [currentPage in OutboundCheckoutPage]: boolean } = {
    collectionDate: numOutboundLabels > 0,
    post: !hasOutboundDhlLabels || !!basket.collectionDate,
    packingGuide: true,
    review: basket.packingGuideAccepted,
    payment: true
  };

  const getHasPermissions = (currentPage: OutboundCheckoutPage) => {
    for(const [ page, filter ] of Object.entries(checkoutPagePermissions)) {
      if (filter && (currentPage === page)) {
        return true;
      }
      if (!filter) {
        return false;
      }
    }
    return false;
  };

  const currentPage = checkoutPages.find(page => checkoutPageUrls[page] === currentLocation);

  if (!currentPage) {
    return <VersionSensitiveNavigate to="/" replace={true}/>;
  }

  if (!getHasPermissions(currentPage)) {
    if (getHasPermissions("collectionDate")) {
      return <VersionSensitiveNavigate
        to={hasOutboundDhlLabels ? firstCheckoutPageUrlWithDhl : firstCheckoutPageUrlWithoutDhl}
        replace={true}
      />;
    }
    return <VersionSensitiveNavigate to={"/"} replace={true}/>;
  }

  return <>
    <Outlet />
  </>;
}