export type Option = {
  text: string;
  mobileText?: string;
  key: string | number;
}

export default function RadioSelector ({ options, value, onSelect }: { options: Option[]; value: string | number; onSelect: (key: string | number) => void }) {
  return <div className="flex flex-row border-4 border-dpGreenDark rounded-lg w-fit font-bold">
    {
      options.map((option, i) => (
        <button
          key={option.key}
          className={ `px-2 xs:px-4 py-2 border-dpGreenDark ${value === option.key ? "bg-dpGreen text-white": "text-dpGreenDark"} ${ i !== 0 ? "border-l-4" : ""}`}
          onClick={() => onSelect(option.key)}
        >
          <div className="hidden xs:block">{option.text}</div>
          <div className="xs:hidden">{option.mobileText || option.text}</div>
        </button>
      ))
    }
  </div>;
}