import { useEffect, useState } from "react";
import { useCreateJWTMutation } from "reduxStore/services/api-v2/auth";

import BtnGreen from "components/atoms/BtnGreen";
import VersionSensitiveLink from "components/atoms/VersionSensitiveLink";
import InputPair from "components/molecules/InputPair";

export default function Login({ className }: { className?: string }) {
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");

  const [ logIn, logInResult ] = useCreateJWTMutation();

  useEffect(() => {
    document.title = "DropPoint | Login";
  }, []);

  return <div className={`flex flex-col items-center w-full ${className || ""}`}>
    <form className="flex flex-col items-start max-w-full">
      <InputPair
        value={email}
        label="Email"
        labelClassName="w-28"
        inputClassName="border-1 py-2 px-4 rounded-lg w-60 max-w-full"
        placeholder="Email"
        type="email"
        name="email"
        onChange={e => {
          setEmail(e.currentTarget.value);
        }}
        className="mt-2 sm:!items-center max-w-full"
        data-test-id="log-in-email-input"
      />
      <InputPair
        type="password"
        value={password}
        label="Password"
        labelClassName="w-28"
        inputClassName="border-1 py-2 px-4 rounded-lg w-60 max-w-full"
        placeholder="Password"
        onChange={e => {
          setPassword(e.currentTarget.value);
        }}
        className="mt-2 sm:!items-center max-w-full"
        data-test-id="log-in-password-input"
      />
      <div className="flex flex-row mt-4 items-center">
        <BtnGreen
          className="mr-4 py-1"
          disabled={!email || !password}
          onClick={async e => {
            e.preventDefault();
            try {
              await logIn({
                email,
                password
              }).unwrap();
            } catch (e) {
              console.error(e);
            }
            return false;
          }}
          data-test-id="log-in-submit-btn"
        >Log in</BtnGreen>
        <VersionSensitiveLink to="/account/reset-password">Forgot password?</VersionSensitiveLink>
      </div>
    </form>
  </div>;
}