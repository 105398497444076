import { combineReducers } from "redux";
import { Action } from "typesafe-actions";

import { dpApi } from "./services/api/api";
import { dpApiV2 } from "./services/api-v2/apiV2";
import { carrierApi } from "./services/carrier-points-api/api";
import { basketReducer } from "./slices/basket/reducer";
import { itemAlertsReducer } from "./slices/item-alerts/reducer";
import { navBarReducer } from "./slices/nav-bar/reducer";
import { EntireState } from "./types";

const appReducer = combineReducers({
  [dpApi.reducerPath]: dpApi.reducer,
  [dpApiV2.reducerPath]: dpApiV2.reducer,
  [carrierApi.reducerPath]: carrierApi.reducer,
  navBar: navBarReducer,
  itemAlerts: itemAlertsReducer,
  basket: basketReducer
});

const rootReducer = (state: EntireState, action: Action) => {
  return appReducer(state, action);
};

export default rootReducer;
