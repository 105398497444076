import { Outlet } from "react-router-dom";

import BaseLayout from "./BaseLayout";

const ContentPage = ({ children }: { children?: React.ReactNode }) => {
  return <BaseLayout>
    {/* Absolute-positioned children should be relative to this */}
    <div className="relative w-full flex flex-col items-center">
      <div className="w-full h-fit max-w-screen-xl pb-20 px-4 md:px-12 lg:px-20 pt-4 md:pt-6 lg:pt-8 grow-1">
        {children || <Outlet />}
      </div>
    </div>
  </BaseLayout>;
};

export default ContentPage;