import { useDispatch, useSelector } from "react-redux";
import HappyPlanetAutumn from "assets/images/misc/happy-planet-autumn.png";
import HappyPlanetChristmas from "assets/images/misc/happy-planet-christmas.png";
import { setSaleBannerHidden } from "reduxStore/slices/nav-bar/actions";
import { selectSaleBannerHidden } from "reduxStore/slices/nav-bar/selectors";

export default function SaleBanner () {
  const hidden = useSelector(selectSaleBannerHidden);
  const dispatch = useDispatch();
  const currentDate = new Date();
  const isAutumn = [ 9, 10 ].includes(currentDate.getMonth());

  if (hidden) return null;
  return <>
    <div className={`z-20 flex flex-col w-full items-center sticky bottom-0 left-0 right-0 shadow-md ${isAutumn ? "bg-dpOrangeMedium" : "bg-dpGreen"}
     pb-4 pt-1 text-white border-t-2`}>
      <div className="w-full 2xs:w-100 xs:w-120 flex relative justify-center">
        <img src={isAutumn ? HappyPlanetAutumn : HappyPlanetChristmas} className="hidden 4xs:block w-32 sm:w-40 absolute left-8 -top-6 sm:-top-12" alt="Happy planet icon" />
        <div className="flex flex-col 4xs:pl-36">
          <div className="flex flex-row-reverse"><button onClick={() => dispatch(setSaleBannerHidden(true))}><b>X</b></button></div>
          <div>
            Thanks for <b className={isAutumn ? "text-dpGreenDark" : "text-dpOrange"}>20k</b> parcels!!
          </div>
          <div>
          Have <b className={isAutumn ? "text-dpGreenDark" : "text-dpOrange"}>20% off</b>, on us!
          </div>
          <div>
            <i>in <a href="/faq" className={`underline ${isAutumn ? "text-dpGreenDark" : "text-dpOrange"}`}>our courier dropoff sale</a></i>
          </div>
        </div>
      </div>
    </div>
  </>;
}