import { getInboundPrice, getOutboundPrice, inboundCarrierOptions, outboundCarrierOptions } from "@utils/carrierOptions";
import { formatPrice } from "@utils/money";
import { InboundServiceName, OutboundServiceName, ServiceName } from "reduxStore/services/api-v2/orders";

export default function SaleSavingsTag({
  tier,
  service,
  qty = 1,
  isOutbound,
  price,
  originalPrice
} : {
  tier?: string | number,
  service?: ServiceName,
  qty?: number,
  isOutbound?: boolean,
  price?: number,
  originalPrice?: number
}) {
  if (!((originalPrice && price) || (service && tier))) {
    throw Error("Must provide either originalPrice and price or service and tier");
  }
  const basePrice = originalPrice || (tier ? (isOutbound ? outboundCarrierOptions[service as OutboundServiceName][tier].price : inboundCarrierOptions[service as InboundServiceName][tier].price) : 0);
  const newPrice = price || (tier ? (isOutbound ? getOutboundPrice(service as OutboundServiceName, tier) : getInboundPrice(service as InboundServiceName, tier)) : 0);

  if (newPrice === basePrice) return null;

  return <div className="ml-6 text-sm">
    <b className="text-dpOrange">(-{formatPrice((basePrice - newPrice) * qty)})</b>
    <span className="text-dpGray ml-1 line-through">{formatPrice(basePrice * qty)}</span>
  </div>;
}