import { ButtonProps } from "types/dom";

const BtnBase = ({ children, className, disabled, ...props }: ButtonProps) => {
  return <button
    {...props}
    className={`
        ${className} rounded-lg font-bold px-4 py-2 border-2
        focus-visible:border-4 text-left ${disabled ? "" : "hover:underline"}
    `}
    disabled={disabled}
  >
    {children}
  </button>;
};

export default BtnBase;