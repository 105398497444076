import { useJsApiLoader } from "@react-google-maps/api";

const LIBRARIES_TO_LOAD = [ "places" ];

const useGoogleMaps = () => {
  return useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY as string,
    libraries: LIBRARIES_TO_LOAD as any
  });
};

export default useGoogleMaps;